<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <div v-if="reports !== null">

      <!-- Table Container Card -->

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>نمایش</label>
            <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50 w-50"
            />
            <label>تعداد</label>
          </b-col>
          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="reports"
          responsive
          :fields="myTableColumns"
          primary-key="id"
          show-empty
          :sort-by.sync="sortBy"
          bordered
          striped
          empty-text="موردی موجود نیست!"
      >

        <!-- Column: createDate -->
        <template #cell(createDate)="data">
                       <span>
                        {{ data.item.createDate.slice(11, 16) }}
                        -
                        {{ createJalaliDate(data.item.createDate.slice(0, 10)) }}
                      </span>
        </template>
        <template #cell(commentInfo)="data">
                       <span>
                       {{ data.item.commentInfo.description }}
                      </span>
        </template>
        <template #cell(objectInfo)="data">
          <a :href="returnUrl +`magazine/${data.item.commentInfo.objectInfo.id}/${data.item.commentInfo.objectInfo.seourl}` "
             target="_blank">
                       <span>
                       {{ data.item.commentInfo.objectInfo.title }}
                      </span>
          </a>
        </template>
        <!-- Column: delete -->
        <template #cell(delete)="data">
          <b-link class="text-danger" v-b-modal.modal-delete-blog @click="showModal(data.item.objectId)">
            <feather-icon size="20" icon="TrashIcon"/>
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalCounts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon style="transform: rotate(180deg)"
                              icon="ChevronLeftIcon"
                              size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </div>
    <b-modal
        id="modal-delete-blog"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteComment(deleteItem)"
    >
      <span>در صورت تایید , این دیدگاه حذف خواهد شد.آیا حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BTab, BTabs,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CommentDeleteByRequest, CommentGetAllRequest, CommentShowCommentsByRequest} from "@/libs/Api/Comments";
import {ReportsGetAllRequest} from "@/libs/Api/Reports";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  name: "BlogsCommentsReports",
  data() {
    return {
      reports: null,
      returnUrl: Helper.returnUrl,
      currentPage: 1,
      totalCounts: null,
      deleteItem: null,
      sortBy: '',
      perPage: 10,
      showOverlay: false,
      allCommentsIds: false,
      isActive: '',
      type: 2,
      isActiveOptions: [
        {label: 'همه', value: ''},
        {label: 'تایید شده ها', value: true},
        {label: 'تایید نشده ها', value: false},
      ],
      commentActivations: [],
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'reportId', label: 'شناسه گزارش', sortable: true},
        {key: 'commentInfo', label: 'متن دیدگاه', sortable: true},
        {key: 'objectInfo', label: 'نام بلاگ', sortable: true},
        {key: 'reason', label: 'توضیحات گزارش', sortable: true},
        {key: 'createDate', label: 'تاریخ ثبت', sortable: true},
        {key: 'delete', label: 'حذف نظر'},
      ],
    }
  },
  async created() {
    await this.getReports(this.perPage, this.currentPage, this.type);
  },
  methods: {
    async getReports(count, pageNumber, type) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        pageNumber: pageNumber,
        count: count,
        type: type,
        childReportType: 9,
      }

      let reportsGetAllRequest = new ReportsGetAllRequest(_this);
      reportsGetAllRequest.setParams(data);
      await reportsGetAllRequest.fetch(function (content) {
        _this.reports = content.reports;
        _this.totalCounts = content.count;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async deleteComment(param) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        id: param
      }

      let commentDeleteByRequest = new CommentDeleteByRequest(_this);
      commentDeleteByRequest.setParams(data);
      await commentDeleteByRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        _this.getReports(_this.perPage, _this.currentPage, _this.type)
        _this.showOverlay = false;
      }, function (error) {
        console.log(error)
        _this.showOverlay = true;
      })
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    isActive: function (nv, ov) {
      this.getReports(this.perPage, this.currentPage, nv);
    },
    perPage: function (nv, ov) {
      this.getReports(nv, this.currentPage, this.isActive);
    },
    currentPage: function (nv, ov) {
      this.getReports(this.perPage, nv, this.isActive);
    },
    allCommentsIds: function (nv, ov) {
      let _this = this;
      if (nv === true) {
        _this.comments.forEach(el => {
          _this.commentActivations.push(el.commentId);
        })
      } else {
        _this.commentActivations = [];
      }
    },
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormCheckbox,
    BTab,
    BTabs,
    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
