import WebServiceRequest from '../Api/WebServiceRequest'

class ReportsGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Report/ReportsGetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ReportsGetByIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Report/GetById')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ReportsGetByObjectIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Report/GetByObjectId')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    ReportsGetAllRequest,
    ReportsGetByIdRequest,
    ReportsGetByObjectIdRequest
}