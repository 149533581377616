<template>
  <div>
    <!-- Table Container Card -->
    <b-card
        class="mb-0"
        no-body
    >

      <b-tabs class="mx-2 my-1">

        <b-tab active>
          <template #title>
            <span class="d-inline">نظرات مقالات</span>
          </template>
          <BlogsCommentsReports/>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-inline">نظرات محصولات</span>
          </template>
          <ProductCommentsReports/>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-inline">نظرات آکادمی</span>
          </template>
          <AcademyCommentsReports/>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-inline">نظرات خدمات</span>
          </template>
          <ServiceCommentsReports/>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-inline">نظرات فروشگاه ها</span>
          </template>
          <VendorCommentReports/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BTab, BTabs,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CommentDeleteByRequest, CommentGetAllRequest, CommentShowCommentsByRequest} from "@/libs/Api/Comments";
import VendorComments from "@/views/pages/comments/VendorComments";
import AcademyComments from "@/views/pages/comments/AcademyComments";
import ServicesComments from "@/views/pages/comments/ServicesComments";
import BlogsCommentsReports from "@/views/pages/reports/BlogsCommentsReports";
import AcademyCommentsReports from "@/views/pages/reports/AcademyCommentsReports";
import ServiceCommentsReports from "@/views/pages/reports/ServiceCommentsReports";
import ProductCommentsReports from "@/views/pages/reports/ProductCommentsReports";
import VendorCommentReports from "@/views/pages/reports/VendorCommentReports";

export default {
  name: "ReportsList",
  title: "لیست گزارشات تخلف",
  data() {
    return {}
  },
  methods: {},
  components: {
    ProductCommentsReports,
    ServiceCommentsReports,
    AcademyCommentsReports,
    BlogsCommentsReports,
    ServicesComments,
    AcademyComments,
    VendorCommentReports,
    VendorComments,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormCheckbox,
    BTab,
    BTabs,
    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
