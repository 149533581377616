import WebServiceRequest from '../Api/WebServiceRequest'

class CommentGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Comment/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class CommentGetAllWithNotConfirmedRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Comment/GetAllWithNotConfirmed')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class CommentGetAllNotConfirmedRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Comment/GetAllNotConfirmed')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class CommentShowCommentsByRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Comment/ShowCommentsBy')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class CommentDeleteByRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Comment/DeleteBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    CommentGetAllRequest,
    CommentGetAllWithNotConfirmedRequest,
    CommentShowCommentsByRequest,
    CommentDeleteByRequest,
    CommentGetAllNotConfirmedRequest
}
