<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <div v-if="comments !== null">

      <!-- Table Container Card -->

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              cols="12"
              md="2"
          >
            <label>نمایش</label>
            <v-select
                v-model="perPage"
                :clearable="false"
                :options="perPageOptions"
                class="per-page-selector d-inline-block mx-50 w-50"
            />
            <label>تعداد</label>
          </b-col>


          <b-col
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              cols="12"
              md="1"
          >
            <b-form-checkbox
                id="allCommentsIds"
                v-model="allCommentsIds"
            />
            <label for="allCommentsIds">انتخاب همه</label>
          </b-col>

          <b-col
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              cols="12"
              md="2"
          >
            <b-button
                :disabled="commentActivations.length === 0"
                variant="primary"
                @click="submitChanges"
            >
              <span class="text-nowrap">تغییر وضعیت</span>
            </b-button>
          </b-col>
          <b-col
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              cols="12"
              md="2"
          >
            <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          :fields="myTableColumns"
          :items="comments"
          bordered
          class="position-relative"
          :sort-by.sync="sortBy"
          empty-text="موردی موجود نیست!"
          primary-key="id"
          responsive
          show-empty
          striped
      >
        <!-- Column: commentId -->
        <template #cell(commentId)="data">
          <b-form-checkbox
              v-model="commentActivations"
              :value="data.item.commentId"
          />
        </template>

        <template #cell(userId)="data">
          <span class="d-block">{{ data.item.userInfo.firstName }} {{ data.item.userInfo.lastName }}</span>
          <small class="text-muted">@{{ data.item.userId }}</small>
        </template>

        <!-- Column: createDate -->
        <template #cell(createDate)="data">
                       <span>
                        {{ data.item.createDate.slice(11, 16) }}
                        -
                        {{ createJalaliDate(data.item.createDate.slice(0, 10)) }}
                      </span>
        </template>


        <template #cell(objectInfo)="data">
          <a :href="returnUrl +`${data.item.objectInfo.seourl}` "
             target="_blank">
                       <span>
                       {{ data.item.objectInfo.title }}
                      </span>
          </a>
        </template>
        <template #cell(isShow)="data">
          <span v-if="data.item.isShow" class="text-primary">تایید شده</span>
          <span v-else class="text-danger">تایید نشده</span>
        </template>

        <!-- Column: edit -->
        <template #cell(edit)="data">
          <b-link :to="{ name: 'pages-blog-comments-edit', params: { id: data.item.commentId } }">
            <feather-icon icon="EditIcon" size="20"/>
          </b-link>
        </template>

        <!-- Column: delete -->
        <template #cell(delete)="data">
          <b-link v-b-modal.modal-delete-vendor class="text-danger" @click="showModal(data.item.commentId)">
            <feather-icon icon="TrashIcon" size="20"/>
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
              cols="12"
              sm="6"
          >
            <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
              cols="12"
              sm="6"
          >

            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalCounts"
                class="mb-0 mt-1 mt-sm-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon"
                              size="18"
                              style="transform: rotate(180deg)"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </div>
    <b-modal
        id="modal-delete-vendor"
        cancelTitle="انصراف"
        centered
        ok-title="حذف"
        @ok="deleteComment(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BTab, BTabs,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CommentDeleteByRequest,
  CommentGetAllRequest,
  CommentShowCommentsByRequest,
  CommentGetAllWithNotConfirmedRequest
} from "@/libs/Api/Comments";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  name: "ProductComments",
  data() {
    return {
      comments: null,
      currentPage: 1,
      totalCounts: null,
      deleteItem: null,
      sortBy: '',
      perPage: 10,
      returnUrl: Helper.returnUrl,

      showOverlay: false,
      allCommentsIds: false,
      isActive: '',
      type: 1,

      commentActivations: [],
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'commentId', label: 'انتخاب'},
        {key: 'objectInfo', label: 'نام فروشگاه', sortable: true},
        {key: 'description', label: 'متن', sortable: true},
        {key: 'createDate', label: 'تاریخ ثبت', sortable: true},
        {key: 'isShow', label: 'وضعیت', sortable: true},
        {key: 'delete', label: 'حذف'},
      ],
    }
  },
  async created() {
    // await this.getCommentsNotConfirmed(this.perPage, this.currentPage, this.type)
    await this.getComments(this.perPage, this.currentPage);
  },
  methods: {

    async getComments(count, pageNumber) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        pageNumber: pageNumber,
        count: count,
        searchCommand: '',
        type: 4,
      }
      let commentGetAllWithNotConfirmedRequest = new CommentGetAllWithNotConfirmedRequest(_this);
      commentGetAllWithNotConfirmedRequest.setParams(data);
      await commentGetAllWithNotConfirmedRequest.fetch(function (content) {
        _this.comments = content.data.comments
        _this.totalCounts = content.data.commentsCount;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async submitChanges() {
      let _this = this;
      _this.showOverlay = true;

      let commentShowCommentsByRequest = new CommentShowCommentsByRequest(_this);
      commentShowCommentsByRequest.setParams(_this.commentActivations);
      await commentShowCommentsByRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات انجام شد.`,
          },
        })
        _this.getComments(_this.perPage, _this.currentPage);
        _this.allCommentsIds = false;
        _this.commentActivations = [];
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        _this.getComments(_this.perPage, _this.currentPage);
        _this.allCommentsIds = false;
        _this.commentActivations = [];
        console.log(error)
      })
    },
    async deleteComment(param) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        id: param
      }

      let commentDeleteByRequest = new CommentDeleteByRequest(_this);
      commentDeleteByRequest.setParams(data);
      await commentDeleteByRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        _this.getComments(_this.perPage, _this.currentPage)
        _this.showOverlay = false;
      }, function (error) {
        console.log(error)
        _this.showOverlay = true;
      })
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    isActive: function (nv, ov) {
      if (nv) {
        this.getComments(this.perPage, this.currentPage, nv);
      }
    },
    perPage: function (nv, ov) {
      this.getComments(nv, this.currentPage, this.isActive);
    },
    currentPage: function (nv, ov) {
      this.getComments(this.perPage, nv, this.isActive);
    },
    allCommentsIds: function (nv, ov) {
      let _this = this;
      if (nv === true) {
        _this.comments.forEach(el => {
          _this.commentActivations.push(el.commentId);
        })
      } else {
        _this.commentActivations = [];
      }
    },
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormCheckbox,
    BTab,
    BTabs,
    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
